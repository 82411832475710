import React, { useState, useRef, useEffect } from "react";
import Typography from "@mui/material/Typography";
import TemplateAdd from "../../../common/TemplateUtils/TemplateAdd";
import ImageSection from "../../../common/TemplateUtils/ImageSection";
import LocationSection from "../../../common/TemplateUtils/LocationSection";
import InformationSection from "../../../common/TemplateUtils/InformationSection";
import CommentsSection from "../../../common/TemplateUtils/CommentsSection";
import TemplateAccord from "../../../common/TemplateUtils/TemplateAccord";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import CheckDescription from "./CheckDescription";
import AdditionalSection from "./AdditionalSection";
import uuid from "react-uuid";
import Grid from "@mui/material/Grid";
import moment from "moment";
import TextInput from "./TextInput";
import Temprature from "./Temprature";
import EditIcon from "@mui/icons-material/Edit";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import RemoveCircleOutlineRoundedIcon from "@mui/icons-material/RemoveCircleOutlineRounded";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import { BootstrapTooltip, timestamp } from "../../../utils/services";
import { Fade, Paper, Popper } from "@mui/material";

const MainCategory = (props) => {
  const {
    lineItem,
    categoryId,
    isInspection,
    renamingId,
    renId,
    lineId,
    AllCategoryList,
    updateTemplate,
    activeRename,
    isChanged,
    checkActiveFocus,
    TriggerClick,
    selectedID,
  } = props;

  const items = lineItem.line_input_controls;
  const [expanded, setExpanded] = useState("");
  const [accordOpen, setAccordOpen] = useState(false);
  const [menu, setMenu] = useState(false);
  const [ActiveId, setActiveId] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [state, setState] = useState({
    editDesc: false,
    editDescText: "",
    isExpanded: items.reduce((acc, item) => {
      acc[item.line_input_control_id] = true;
      return acc;
    }, {}),
    // anchorEl: null,
    selectedItem: null,
    editLocation: false,
    isStaticExpanded: {
      description: true,
      media: true,
      location: true,
      Description: true,
      Media: true,
      Location: true,
      information:true,
      Information:true
    },
  });
  const {
    editDesc,
    editDescText,
    isExpanded,
    // anchorEl,
    selectedItem,
    isStaticExpanded,
  } = state;
  const [placement, setPlacement] = React.useState();

  const handleClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setPlacement(newPlacement);
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const unique_id = uuid();
  const small_id = unique_id;
  const uuids1 = uuid();
  const uuids2 = uuid();
  const uuids3 = uuid();
  const uuids4 = uuid();
  const uuids5 = uuid();
  const stopActive = (event) => event.stopPropagation();

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : "inspections");
    setAccordOpen(!accordOpen);
  };

  const OpenMenu = (event, id) => {
    event.stopPropagation();
    stopActive(event);
    setMenu(!menu);
    setActiveId(id);
  };

  const CloseMenu = (event) => {
    stopActive(event);
    setMenu(!menu);
  };

  const HandleCreateNewDescription = (id) => {
    const val = {
      line_input_control_id: small_id,
      uuid: small_id,
      prompt: null,
      order: null,
      created_at: timestamp(),
      updated_at: timestamp(),
      is_deleted: 0,
      values: [
        {
          value: "check 1",
          is_selected: false,
          uuid: uuids1,
          created_at: timestamp(),
          updated_at: timestamp(),
          is_deleted: 0,
          order: 1,
        },
        {
          value: "check 2",
          is_selected: false,
          uuid: uuids2,
          created_at: timestamp(),
          updated_at: timestamp(),
          is_deleted: 0,
          order: 2,
        },
        {
          value: "check 3",
          is_selected: false,
          uuid: uuids3,
          created_at: timestamp(),
          updated_at: timestamp(),
          is_deleted: 0,
          order: 3,
        },
      ],
      type: "Additional Input",
      data: [],
    };
    const catList = AllCategoryList?.template?.categories;
    catList?.map((cat) =>
      cat?.lines?.map((line) => {
        if (line.line_id === id) {
          line.line_input_controls.push(val);
        }
      })
    );
    updateTemplate("noToast");
  };

  const addNewCommentHandle = (id) => {
    let newData = {
      comment_id: small_id,
      uuid: small_id,
      updated_at: timestamp(),
      prompt: null,
      is_mannual_prompt: 0,
      recommendation: "<p></p>",
      order: null,
      defaultRating_id: null,
      default_location: [],
      is_deleted: 0,
      is_selected: 0,
      comment_input_controls: [],
    };
    // setCommentsData([...commentsData, newData]);
    const catList = AllCategoryList?.template?.categories;
    const categoriesData = catList.findIndex(
      (cat) => cat.category_id === categoryId
    );
    let lineIndex = catList[categoriesData].lines.findIndex(
      (line) => line.line_id === id
    );
    catList[categoriesData].lines[lineIndex].comments.push(newData);
    catList[categoriesData].lines[lineIndex].updated_at = timestamp();
    updateTemplate("noToast");
  };

  let DescArray = items.filter((obj) => {
    return obj.type === "Description";
  });

  let CheckBoxArray = items.filter((obj) => {
    return obj.type === "CheckBox";
  });

  let TempArray = items.filter((obj) => {
    return obj.type === "Temperature";
  });

  let TextArray = items.filter((obj) => {
    return obj.type === "TextBox";
  });

  const AdditionalIp = items.filter((obj) => {
    return obj.type === "Additional Input";
  }).sort((a, b) => a.order - b.order);

  const UnAdditional = items.filter((obj) => {
    return obj.type !== "Additional Input";
  });

  let MediaArray = items.filter((obj) => {
    return obj.type === "Media";
  });

  let LocationArray = items.filter((obj) => {
    return obj.type === "Location";
  });

  let InformationArray = items.filter((obj) => {
    return obj.type === "Information";
  });

  const commLines = lineItem?.comments;
  const [additional, setAdditional] = useState([]);
  const [commentLines, setCommentLines] = useState([]);

  React.useEffect(() => {
    setAdditional(AdditionalIp);
  }, [items]);

  React.useEffect(() => {
    setCommentLines(commLines);
  }, [items]);

  const reorder = (data, startIndex, endIndex) => {
    const result = Array.from(data);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const [cmt, setCmt] = React.useState("");

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const reorderedItems = reorder(
      additional,
      result.source.index,
      result.destination.index
    );
    reorderedItems.forEach((x, i) => {
      x.order = i + 1;
      x.updated_at = timestamp();
      return x;
    });
    setAdditional(reorderedItems);
    let additionalObjectsArray = JSON.parse(JSON.stringify(additional));
    additionalObjectsArray.sort((a, b) => a.order - b.order);
    const allLines = UnAdditional.concat(additionalObjectsArray);
    const catList = AllCategoryList?.template?.categories;
    catList.forEach((cat) =>
      cat.lines.forEach((line) => {
        if (line.line_id === selectedID) {
          line.line_input_controls = allLines;
          line.updated_at = timestamp();
          updateTemplate("noToast");
        }
      })
    );
  };

  const onDragEndComment = (result) => {
    if (!result.destination) {
      return;
    }

    const reorderedItems = reorder(
      commentLines,
      result.source.index,
      result.destination.index
    );

    reorderedItems.forEach((x, i) => {
      x.order = i + 1;
      x.updated_at = timestamp();
      return x;
    });

    setCommentLines(reorderedItems);

    const catList = AllCategoryList?.template?.categories;
    catList.map((cat) =>
      cat.lines.map((line) => {
        if (line.line_id === selectedID) {
          line.comments = commentLines;
          line.updated_at = timestamp();
          updateTemplate("noToast");
        }
      })
    );
  };

  const DescHolder = [
    {
      line_input_control_id: uuids2 /*uuid */,
      uuid: uuids2,
      updated_at: timestamp(),
      prompt: "Description",
      values: [
        {
          value: "check 1",
          uuid: uuids3,
          is_selected: false,
          created_at: timestamp(),
          updated_at: timestamp(),
          is_deleted: 0,
          order: 1,
        },
        {
          value: "check 2",
          uuid: uuids4,

          is_selected: false,
          created_at: timestamp(),
          updated_at: timestamp(),
          is_deleted: 0,
          order: 2,
        },
        {
          value: "check 3",
          uuid: uuids5,

          is_selected: false,
          created_at: timestamp(),
          updated_at: timestamp(),
          is_deleted: 0,
          order: 3,
        },
      ],
      type: "Description",
      data: [],
    },
  ];

  const MediaHolder = [
    {
      line_input_control_id: uuids4 /*uuid */,
      created_at: timestamp(),
      updated_at: timestamp(),
      uuid: uuids4,
      prompt: "Media",
      values: [],
      type: "Media",
      data: [],
    },
  ];

  const MediaCmtHolder = [
    {
      comment_input_control_id: small_id,
      uuid: small_id,
      created_at: timestamp(),
      updated_at: timestamp(),
      prompt: "Media",
      values: [],
      type: "Media",
      data: [],
    },
  ];

  const InfoHolder = [
    {
      line_input_control_id: uuids3 /*uuid */,
      uuid: uuids3,
      created_at: timestamp(),
      updated_at: timestamp(),
      prompt: "Information",
      values: [
        {
          value: "",
        },
      ],
      type: "Information",
      data: [],
    },
  ];

  const LocationHolder = [
    {
      line_input_control_id: uuids5 /*uuid */,
      uuid: uuids5,
      created_at: timestamp(),
      updated_at: timestamp(),
      prompt: "Location",
      values: [],
      type: "Location",
      data: [],
    },
  ];

  const cmtLocHolder = [
    {
      comment_input_control_id: small_id,
      uuid: small_id,
      created_at: timestamp(),
      updated_at: timestamp(),
      prompt: "Location",
      is_manual_promt_edit: 0,
      values: [],
      type: "Location",
      data: [],
    },
  ];

  const cmtRating = [
    {
      comment_input_control_id: small_id,
      uuid: small_id,
      updated_at: timestamp(),
      prompt: "Rating",
      values: [],
      type: "Rating",
      data: [],
    },
  ];

  const handleUpdateDesc = (selectedID, type) => {
    setState((prevState) => ({ ...prevState, editDesc: false }));
    let catList = AllCategoryList?.template?.categories;
    const categoriesDataIndex = catList.findIndex(
      (cat) => cat.category_id === categoryId
    );
    const lineIndex = catList[categoriesDataIndex].lines.findIndex(
      (line) => line.line_id === lineId
    );
    const locationIndex = catList[categoriesDataIndex].lines[
      lineIndex
    ].line_input_controls.findIndex(
      (info) => info.line_input_control_id === selectedID
    );
    if (locationIndex === -1) {
      let lineUpdatedData;
      if (type.toLowerCase() === "description") {
        lineUpdatedData = DescHolder;
        lineUpdatedData[0].prompt = editDescText;
        lineUpdatedData[0].updated_at = timestamp();
      }
      if (type.toLowerCase() === "location") {
        lineUpdatedData = LocationHolder;
        lineUpdatedData[0].prompt = editDescText;
        lineUpdatedData[0].updated_at = timestamp();
      }
      if (type.toLowerCase() === "media") {
        lineUpdatedData = MediaHolder;
        lineUpdatedData[0].prompt = editDescText;
        lineUpdatedData[0].updated_at = timestamp();
      }
      if (type.toLowerCase() === "information") {
        lineUpdatedData = InfoHolder;
        lineUpdatedData[0].prompt = editDescText;
        lineUpdatedData[0].updated_at = timestamp();
      }
      catList[categoriesDataIndex].lines[lineIndex].line_input_controls = [
        ...catList[categoriesDataIndex].lines[lineIndex].line_input_controls,
        ...lineUpdatedData,
      ];
    } else {
      catList[categoriesDataIndex].lines[lineIndex].line_input_controls[
        locationIndex
      ].prompt = editDescText;
      catList[categoriesDataIndex].lines[lineIndex].line_input_controls[
        locationIndex
      ].updated_at = timestamp();
    }
    updateTemplate("noToast");
  };

  const removeDescription = (selectedID, type, remove) => {
    setState((prevState) => ({
      ...prevState,
      // anchorEl: null,
      isExpanded: { ...isExpanded, [selectedID]: remove ? false : true },
    }));
    setAnchorEl(null);
    let catList = AllCategoryList?.template?.categories;
    const categoriesDataIndex = catList.findIndex(
      (cat) => cat.category_id === categoryId
    );
    const lineIndex = catList[categoriesDataIndex].lines.findIndex(
      (line) => line.line_id === lineId
    );
    const locationIndex = catList[categoriesDataIndex].lines[
      lineIndex
    ].line_input_controls.findIndex(
      (info) => info.line_input_control_id === selectedID
    );
    if (locationIndex === -1) {
      let lineUpdatedData;
      if (type.toLowerCase() === "description") {
        lineUpdatedData = DescHolder;
        lineUpdatedData[0].is_disabled = remove;
        lineUpdatedData[0].updated_at = timestamp();
      }
      if (type.toLowerCase() === "location") {
        lineUpdatedData = LocationHolder;
        lineUpdatedData[0].is_disabled = remove;
        lineUpdatedData[0].updated_at = timestamp();
      }
      if (type.toLowerCase() === "media") {
        lineUpdatedData = MediaHolder;
        lineUpdatedData[0].is_disabled = remove;
        lineUpdatedData[0].updated_at = timestamp();
      }
      if (type.toLowerCase() === "information") {
        lineUpdatedData = InfoHolder;
        lineUpdatedData[0].is_disabled = remove;
        lineUpdatedData[0].updated_at = timestamp();
      }
      catList[categoriesDataIndex].lines[lineIndex].line_input_controls = [
        ...catList[categoriesDataIndex].lines[lineIndex].line_input_controls,
        ...lineUpdatedData,
      ];
    } else {
      catList[categoriesDataIndex].lines[lineIndex].line_input_controls[
        locationIndex
      ].is_disabled = remove;
      catList[categoriesDataIndex].lines[lineIndex].line_input_controls[
        locationIndex
      ].updated_at = timestamp();
    }
    updateTemplate("noToast");
  };

  const handleExpansion = (itemData, isStatic) => {
    if (!itemData.is_disabled) {
      let newData;
      if (isStatic) {
        newData = isStaticExpanded;
        newData[itemData.type] = !isStaticExpanded[itemData.type];
      } else {
        newData = isExpanded;
        newData[itemData.line_input_control_id] =
          !isExpanded[itemData.line_input_control_id];
      }
      setState((prevState) => ({
        ...prevState,
        isExpanded: newData,
      }));
    }
  };

  const inputRef = useRef(null);
  useEffect(() => {
    if (editDesc && inputRef.current) inputRef.current.focus();
  }, [editDesc]);

  const renderTEComponents = (itemData, type, isStatic, accordionDetail) => {
    return (
      <Grid
        container
        display={"flex"}
        mb={3}
        key={isStatic ? itemData.type : itemData?.line_input_control_id}
      >
        <Grid item xs={12}>
          <Accordion
            expanded={
              itemData.is_disabled
                ? false
                : isStatic
                ? isStaticExpanded[itemData?.type]
                : isExpanded[itemData?.line_input_control_id] === undefined
                ? true
                : isExpanded[itemData?.line_input_control_id]
            }
            onChange={() => handleExpansion(itemData, isStatic)}
            className="accordionBox accordionSumFocus"
          >
            <AccordionSummary className="accordionSumDesc accordionSumFocus">
              <Grid container gap={0.7}>
                <Grid
                  item
                  xs={itemData?.is_disabled ? 11.2 : 9.3}
                  md={itemData?.is_disabled ? 11.2 : 9.3}
                  sm={itemData?.is_disabled ? 11.3 : 10.6}
                  lg={itemData?.is_disabled ? 11.3 : 10.6}
                >
                  {editDesc &&
                  ((isStatic && itemData?.type === selectedItem?.type) ||
                    (!isStatic &&
                      itemData?.line_input_control_id ===
                        selectedItem?.line_input_control_id)) ? (
                    <ClickAwayListener
                      mouseEvent="onMouseDown"
                      touchEvent="onTouchStart"
                      onClickAway={() =>
                        handleUpdateDesc(itemData.line_input_control_id, type)
                      }
                    >
                      <input
                        type="text"
                        name="title"
                        value={editDescText}
                        onKeyDown={(e) =>
                          e.keyCode === 13 &&
                          handleUpdateDesc(itemData.line_input_control_id, type)
                        }
                        onFocus={(event) => event.target.select()}
                        ref={inputRef}
                        autoFocus
                        onClick={(e) => e.stopPropagation()}
                        onChange={(e) =>
                          setState((prevState) => ({
                            ...prevState,
                            editDescText: e.target.value,
                          }))
                        }
                        className="accordionTitle"
                      />
                    </ClickAwayListener>
                  ) : (
                    <Grid container display={"flex"} gap={0.1}>
                      <Grid
                        item
                        xs={!itemData?.is_disabled ? 12 : 4.1}
                        sm={!itemData?.is_disabled ? 11 : 4.1}
                        md={!itemData?.is_disabled ? 12 : 4.1}
                        lg={!itemData?.is_disabled ? 11 : 4.1}
                        display={"flex"}
                      >
                        <Typography
                          className={
                            itemData?.is_disabled
                              ? "accordionTitleDisable"
                              : "accordionTitle"
                          }
                        >
                          {itemData?.prompt}
                        </Typography>
                      </Grid>
                      {itemData?.is_disabled ? (
                        <Grid
                          item
                          xs={12}
                          sm={7.8}
                          md={12}
                          lg={7.8}
                          display={"flex"}
                          alignItems={"center"}
                        >
                          <Typography className="titleDisable">
                            {`*This form element has been removed and will not be published.`}
                          </Typography>
                        </Grid>
                      ) : null}
                    </Grid>
                  )}
                </Grid>
                {itemData?.is_disabled ? (
                  <Grid
                    item
                    xs={0.5}
                    display={"flex"}
                    justifyContent={"flex-end"}
                    alignItems={"center"}
                  >
                    <BootstrapTooltip title={<h1>Add form element</h1>}>
                      <AddCircleOutlineRoundedIcon
                        onClick={(e) => {
                          e.stopPropagation();
                          removeDescription(
                            itemData?.line_input_control_id,
                            type,
                            0
                          );
                        }}
                      />
                    </BootstrapTooltip>
                  </Grid>
                ) : (
                  <>
                    <Grid
                      item
                      sm={0.5}
                      xs={1}
                      md={1}
                      lg={0.5}
                      display={"flex"}
                      justifyContent={"flex-end"}
                      alignItems={"center"}
                    >
                      {!(
                        (isStatic && isStaticExpanded[itemData.type]) ||
                        (!isStatic &&
                        isExpanded[itemData?.line_input_control_id] ===
                          undefined
                          ? true
                          : isExpanded[itemData?.line_input_control_id])
                      ) ? (
                        <ExpandMoreIcon
                        // onClick={(event) => {
                        //   event.stopPropagation();
                        //   handleExpansion(itemData, isStatic);
                        // }}
                        />
                      ) : (
                        <ExpandLessIcon
                        // onClick={(event) => {
                        //   event.stopPropagation();
                        //   handleExpansion(itemData, isStatic);
                        // }}
                        />
                      )}
                    </Grid>
                    {AllCategoryList?.template?.is_texas !== 1 && (
                      <Grid
                        item
                        sm={0.5}
                        xs={1}
                        md={1}
                        lg={0.5}
                        display={"flex"}
                        justifyContent={"flex-end"}
                        alignItems={"center"}
                      >
                        <MoreHorizIcon
                          onClick={(event) => {
                            event.stopPropagation();
                            setState((prevState) => ({
                              ...prevState,
                              // anchorEl: event.currentTarget,
                              selectedItem: itemData,
                            }));
                            setAnchorEl(event.currentTarget);
                            handleClick("bottom-end")(event);
                          }}
                        />
                      </Grid>
                    )}
                  </>
                )}
              </Grid>
            </AccordionSummary>
            <AccordionDetails>{accordionDetail}</AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    );
  };

  return (
    <div>
      {AllCategoryList?.template?.is_texas !== 1 && (
        <Popper
          open={open}
          anchorEl={anchorEl}
          placement={placement}
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper>
                <ClickAwayListener onClickAway={handleClickAway}>
                  <div style={{ padding: "10px" }}>
                   {selectedItem?.type?.toLowerCase() === "information" ? null : <MenuItem
                      style={{ padding: "8px" }}
                      onClick={() => {
                        setState((prevState) => ({
                          ...prevState,
                          editDesc: true,
                          editDescText: selectedItem.prompt,
                          // anchorEl: null,
                        }));
                        setAnchorEl(null);
                      }}
                    >
                      <EditIcon className="editDescIcon" />
                      <Typography className="editDescText">
                        Edit Title
                      </Typography>
                    </MenuItem>}
                    
                      <MenuItem
                        style={{ padding: "8px" }}
                        onClick={() =>
                          removeDescription(
                            selectedItem?.line_input_control_id,
                            selectedItem?.type,
                            1
                          )
                        }
                      >
                        <RemoveCircleOutlineRoundedIcon className="editDescIcon" />
                        <Typography className="editDescText">
                          Remove Form Element
                        </Typography>
                      </MenuItem>
             
                  </div>
                </ClickAwayListener>
              </Paper>
            </Fade>
          )}
        </Popper>
      )}
      <>
        {!isInspection && (
          <>
            {DescArray.length === 0
              ? DescHolder.map((item) =>
                  renderTEComponents(
                    item,
                    "description",
                    true,
                    <CheckDescription
                      item={item}
                      type={item.type}
                      prompt={item.prompt}
                      isPlaceHolder={true}
                      values={item.values}
                      categoryId={categoryId}
                      TriggerClick={TriggerClick}
                      renId={renId}
                      renamingId={(id) => renamingId(id)}
                      AllCategoryList={AllCategoryList}
                      selectedID={item.line_input_control_id}
                      lineId={lineId}
                      updateTemplate={updateTemplate}
                    />
                  )
                )
              : DescArray.map((item) =>
                  renderTEComponents(
                    item,
                    "description",
                    false,
                    <CheckDescription
                      item={item}
                      type={item.type}
                      prompt={item.prompt}
                      isPlaceHolder={false}
                      values={item.values}
                      categoryId={categoryId}
                      TriggerClick={TriggerClick}
                      renId={renId}
                      renamingId={(id) => renamingId(id)}
                      AllCategoryList={AllCategoryList}
                      selectedID={item.line_input_control_id}
                      lineId={lineId}
                      updateTemplate={updateTemplate}
                    />
                  )
                )}
          </>
        )}
        {CheckBoxArray.map((item) => {
          return (
            <>
              <Typography className="maincat"> {item.prompt}</Typography>
              <CheckDescription
                item={item}
                type={item.type}
                prompt={item.prompt}
                values={item.values}
                isPlaceHolder={false}
                categoryId={categoryId}
                TriggerClick={TriggerClick}
                renId={renId}
                renamingId={(id) => renamingId(id)}
                AllCategoryList={AllCategoryList}
                selectedID={item.line_input_control_id}
                lineId={lineId}
                updateTemplate={updateTemplate}
              />
            </>
          );
        })}
        {TempArray.map((item) => {
          return (
            <>
              <Typography
                sx={{ pb: "25px" }}
                style={{ minWidth: "40px", minHeight: "40px" }}
                className="section"
              >
                Numeric input. Select Farenheit or Celcius as default.
              </Typography>
              <Temprature
                data={item.data}
                AllCategoryList={AllCategoryList}
                updateTemplate={updateTemplate}
                selectedID={item.line_input_control_id}
              />
            </>
          );
        })}
        {TextArray.map((item) => {
          return (
            <>
              <Typography
                sx={{ pb: "25px" }}
                style={{ minWidth: "40px", minHeight: "40px" }}
                className="section"
              >
                Text input. Use for short answer information
              </Typography>
              <TextInput
                data={item.data}
                AllCategoryList={AllCategoryList}
                updateTemplate={updateTemplate}
                selectedID={item.line_input_control_id}
              />
            </>
          );
        })}
        {isInspection !== 1 && (
          <>
            <Typography sx={{ mb: "25px" }} className="maincat">
              Additional Inputs
            </Typography>
            <Grid>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="InspDetails">
                  {(provided) => (
                    <div ref={provided.innerRef}>
                      {additional?.map((item, index) => {
                        return (
                          <Draggable
                            draggableId={String(item.line_input_control_id)}
                            // isDragDisabled={active && lists.category_id === id}
                            key={item.line_input_control_id}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                key={index}
                                ref={provided.innerRef}
                                {...provided.dragHandleProps}
                                {...provided.draggableProps}
                              >
                                {
                                  <AdditionalSection
                                    from={`additional_${item.line_input_control_id}`}
                                    type={item.type}
                                    prompt={item.prompt}
                                    values={item.values}
                                    isPlaceHolder={false}
                                    item={item}
                                    categoryId={categoryId}
                                    TriggerClick={TriggerClick}
                                    renId={renId}
                                    activeRename={activeRename}
                                    isChanged={isChanged}
                                    updateTemplate={updateTemplate}
                                    checkActiveFocus={checkActiveFocus}
                                    renamingId={(id) => renamingId(id)}
                                    AllCategoryList={AllCategoryList}
                                    selectedID={item.line_input_control_id}
                                    lineId={lineId}
                                    actions={{
                                      CloseMenu: (event) => CloseMenu(event),
                                      stopActive: (event) => stopActive(event),
                                      OpenMenu: (event, id) =>
                                        OpenMenu(event, id),
                                      ActiveId,
                                      menu,
                                    }}
                                  />
                                }
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              {isInspection !== 1 && (
                <div style={{ paddingBottom: "52px" }}>
                  <TemplateAdd
                    name={"Additional input"}
                    HandleCreateSite={() => HandleCreateNewDescription(lineId)}
                  />
                </div>
              )}
            </Grid>
          </>
        )}
        {!isInspection && (
          <>
            {MediaArray.length === 0
              ? MediaHolder.map((item) =>
                  renderTEComponents(
                    item,
                    "media",
                    true,
                    item?.type === "Media" && (
                      <ImageSection
                        images={item.data}
                        isPlaceHolder={true}
                        item={item}
                        AllCategoryList={AllCategoryList}
                        categoryId={categoryId}
                        lineId={lineId}
                        lineInputControlId={item.line_input_control_id}
                        updateTemplate={updateTemplate}
                      />
                    )
                  )
                )
              : MediaArray.map((item) =>
                  renderTEComponents(
                    item,
                    "media",
                    false,
                    item.type === "Media" && (
                      <ImageSection
                        images={item.data}
                        isPlaceHolder={false}
                        item={item}
                        AllCategoryList={AllCategoryList}
                        categoryId={categoryId}
                        lineId={lineId}
                        lineInputControlId={item.line_input_control_id}
                        updateTemplate={updateTemplate}
                      />
                    )
                  )
                )}
            {LocationArray.length === 0
              ? LocationHolder.map((item) =>
                  renderTEComponents(
                    item,
                    "location",
                    true,
                    <LocationSection
                      locationData={AllCategoryList?.template?.location_data}
                      prompt={item.prompt}
                      isPlaceHolder={true}
                      item={item}
                      AllCategoryList={AllCategoryList}
                      categoryId={categoryId}
                      lineId={lineId}
                      lineInputControlId={item.line_input_control_id}
                      updateTemplate={updateTemplate}
                    />
                  )
                )
              : LocationArray.map((item) =>
                  renderTEComponents(
                    item,
                    "location",
                    false,
                    <LocationSection
                      locationData={AllCategoryList?.template?.location_data}
                      prompt={item.prompt}
                      isPlaceHolder={false}
                      item={item}
                      AllCategoryList={AllCategoryList}
                      categoryId={categoryId}
                      lineId={lineId}
                      lineInputControlId={item.line_input_control_id}
                      updateTemplate={updateTemplate}
                    />
                  )
                )}
            {InformationArray.length === 0
              ? InfoHolder.map((item) => 
                renderTEComponents(
                  item,
                  "information",
                  true,
                    //  <>
                    //   <Typography sx={{ mt: "54px" }} className="maincat">
                    //     {item.prompt}
                    //   </Typography>
                      <InformationSection
                        updateTemplate={updateTemplate}
                        isPlaceHolder={true}
                        item={item}
                        informationData={
                          item.values.length !== 0 ? item.values[0].value : ""
                        }
                        prompt={item.prompt}
                        AllCategoryList={AllCategoryList}
                        categoryId={categoryId}
                        lineId={lineId}
                        lineInputControlId={item.line_input_control_id}
                      />
                    // </>
                )
                  // return (
                  //   <>
                  //     <Typography sx={{ mt: "54px" }} className="maincat">
                  //       {item.prompt}
                  //     </Typography>
                  //     <InformationSection
                  //       updateTemplate={updateTemplate}
                  //       isPlaceHolder={true}
                  //       item={item}
                  //       informationData={
                  //         item.values.length !== 0 ? item.values[0].value : ""
                  //       }
                  //       prompt={item.prompt}
                  //       AllCategoryList={AllCategoryList}
                  //       categoryId={categoryId}
                  //       lineId={lineId}
                  //       lineInputControlId={item.line_input_control_id}
                  //     />
                  //   </>
                  // );
                )
              : InformationArray.map((item) => 
                renderTEComponents(
                  item,
                  "information",
                  false,
                    //  <>
                    //   <Typography sx={{ mt: "54px" }} className="maincat">
                    //     {item.prompt}
                    //   </Typography>
                      <InformationSection
                        updateTemplate={updateTemplate}
                        isPlaceHolder={true}
                        item={item}
                        informationData={
                          item.values.length !== 0 ? item.values[0].value : ""
                        }
                        prompt={item.prompt}
                        AllCategoryList={AllCategoryList}
                        categoryId={categoryId}
                        lineId={lineId}
                        lineInputControlId={item.line_input_control_id}
                      />
                    // </>
                )
                  // return (
                  //   <>
                  //     <Typography sx={{ mt: "54px" }} className="maincat">
                  //       {item.prompt}
                  //     </Typography>
                  //     <InformationSection
                  //       updateTemplate={updateTemplate}
                  //       isPlaceHolder={false}
                  //       item={item}
                  //       informationData={
                  //         item.values.length !== 0 ? item.values[0].value : ""
                  //       }
                  //       prompt={item.prompt}
                  //       AllCategoryList={AllCategoryList}
                  //       categoryId={categoryId}
                  //       lineId={lineId}
                  //       lineInputControlId={item.line_input_control_id}
                  //     />
                  //   </>
                  // );
                )}
          </>
        )}
      </>
      {isInspection !== 1 && (
        <>
          <Typography className="line-header">Comments</Typography>
          <Grid draggable>
            <DragDropContext onDragEnd={onDragEndComment}>
              <Droppable droppableId="InspDetails">
                {(provided) => (
                  <div ref={provided.innerRef}>
                    {commentLines?.length > 0 &&
                      commentLines?.map((comment, index) => {
                        setCmt(comment.comment_id);
                        return (
                          <Draggable
                            draggableId={String(comment.comment_id)}
                            isDragDisabled={accordOpen}
                            key={comment.comment_id}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                key={index}
                                ref={provided.innerRef}
                                {...provided.dragHandleProps}
                                {...provided.draggableProps}
                              >
                                {
                                  <TemplateAccord
                                    expanded={expanded}
                                    from={`comments_${comment.comment_id}`}
                                    accordOpen={accordOpen}
                                    HeaderTitle={comment.prompt}
                                    manualPrompt={comment.is_mannual_prompt}
                                    recommended={comment.recommendation}
                                    handleChange={(panel) =>
                                      handleChange(panel)
                                    }
                                    AllCategoryList={AllCategoryList}
                                    selectedID={comment.comment_id}
                                    TriggerClick={TriggerClick}
                                    activeRename={activeRename}
                                    isChanged={isChanged}
                                    renId={renId}
                                    updateTemplate={updateTemplate}
                                    checkActiveFocus={checkActiveFocus}
                                    renamingId={(id) => renamingId(id)}
                                    lineComId={lineId}
                                    innerTemplate={
                                      <CommentsSection
                                        MediaHolder={MediaCmtHolder}
                                        commentData={comment}
                                        cmtRating={cmtRating}
                                        categoryId={categoryId}
                                        lineId={lineId}
                                        cmtLocHolder={cmtLocHolder}
                                        AllCategoryList={AllCategoryList}
                                        updateTemplate={updateTemplate}
                                        commentId={comment.comment_id}
                                      />
                                    }
                                    actions={{
                                      CloseMenu: (event) => CloseMenu(event),
                                      stopActive: (event) => stopActive(event),
                                      OpenMenu: (event, id) =>
                                        OpenMenu(event, id),
                                      ActiveId,
                                      menu,
                                    }}
                                  />
                                }
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Grid>
          <TemplateAdd
            name={"Add comments"}
            style={{ marginTop: "15px" }}
            HandleCreateSite={() => addNewCommentHandle(lineId)}
          />
        </>
      )}
    </div>
  );
};

export default MainCategory;
